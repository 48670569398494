
          @import "src/assets/styles/variables.module.scss";
        
.editProfile {
  padding: 84px 16px 0px;
  position: relative;
  padding-bottom: 60px;

  input {
    &::placeholder {
      color: #7b7b7b;
      font-size: 16px;
    }
  }

  &__avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $grey_500;
    position: absolute;
    top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__avatarImage {
    position: relative;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__avatarImageCover {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    background-color: rgba(28, 27, 27, 0.5);
  }

  &__default {
    z-index: 1;
  }

  &__details {
    font-size: 18px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 14px;

    &__more {
      margin-bottom: 8px;
    }

    &__less {
      margin-bottom: 5px;
    }
  }

  &__phoneInfo {
    font-size: 12px;
    margin-top: 8px;
    line-height: 20px;
  }

  &__view {
    display: flex;
    justify-content: space-between;

    &__notActive {
      color: #7b7b7b;
      background-color: transparent;
      border-color: $grey;
    }
  }

  &__form {
    &__row {
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $grey_100;
    }

    &__block {
      margin-top: 16px;
    }
  }

  &__achievements {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    height: 56px;
    margin-top: 16px;
    font-weight: 500;
    padding: 0 10px;
  }

  &__work {
    margin-top: 48px;

    &__subtitle {
      margin-top: 16px;
      font-size: 14px;
      color: $main_grey;
      line-height: 22px;
    }
  }

  &__company {
    font-size: 14px;
  }

  &__companyName {
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 48px;
  }

  &__buttonContainer {
    width: 100%;
    height: 112px;
    padding: 16px;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $cream;
  }
}

.background {
  background-color: $background;
  width: 100%;
  height: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__top {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 192px;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__cover {
    position: absolute;
    width: 100%;
    height: 192px;
    top: 0;
    left: 0;
    background-color: rgba(28, 27, 27, 0.5);
  }

  &__add {
    color: $white;
    margin-top: 6px;
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--app-height) - 114px);
}

.block {
  &__input {
    display: block;
    max-height: 56px;
    min-height: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: $white;
    border: 1px solid $grey;
    outline: none;
    width: 100%;
    padding: 16px 15px 16px 15px;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    color: $black;
  }

  &__label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 5px;
  }

  &__roleIcon {
    width: 20px;
    height: 20px;
  }

  &__roleButton {
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}
