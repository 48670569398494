
          @import "src/assets/styles/variables.module.scss";
        
.block {
  width: 100%;
  background-color: $white;
  border-radius: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__username {
    font-weight: 500;
    font-size: 14px;
    line-height: 22.4px;
    color: $black;
  }

  &__time {
    font-weight: 400;
    font-size: 14px;
    line-height: 18.2px;
    color: $main_grey;
  }

  &__headerButton {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: transparent;
    color: $main_grey;
    border: none;
    padding: 0;
  }

  &__content {
    width: 100%;
    padding: 0px 8px 0 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    overflow-wrap: break-word;
  }

  &__footer {
    display: flex;
    padding: 12px 8px;
    gap: 25px;
  }

  &__footerButton {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    padding: 0;
    background-color: transparent;
    font-weight: 400;
    line-height: 18.2px;
    font-size: 14px;
    color: $main_grey;
    min-width: 38px;
  }

  &__footerButtonIcon {
    width: 20px;
    height: 20px;
  }

  &__imgContainer {
    width: 100%;
    height: auto;
    overflow: hidden;
    aspect-ratio: 328/246;
    margin-top: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
