
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  &__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;

    span {
      color: $red;
    }
  }

  &__container {
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 1px 15px 0px;

    &__disabled {
      background-color: $grey_hover;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: $black;
    font-weight: 400;
    line-height: 130%;
    padding: 0;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: $main_grey;
      line-height: 130%;
    }
  }

  &__passwordButton {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: auto;
    color: $black;
    padding: 0;

    &--active {
      color: $main_grey;
    }
  }

  &__error {
    font-size: 14px;
    font-weight: 400;
    color: $red;
    line-height: normal;
  }

  &__leftContent {
    margin-right: 8px;
  }

  &__rightContent {
    margin-left: auto;
    width: max-content;
    white-space: nowrap;
  }

  &--invalid {
    .block {
      &__container {
        border-color: $red;
        background-color: rgba(238, 57, 57, 0.05);
      }
    }
  }


  &--notEditable {
    .block {
      &__input {
        pointer-events: none;
        user-select: none;
      }
    }
  }
}
