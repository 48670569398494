
          @import "src/assets/styles/variables.module.scss";
        
.success {
  padding-top: 48px;

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &__title {
    margin-top: 24px;
    font-size: 32px;
    font-weight: 500;
    color: $black;
    line-height: 42px;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    color: $main_grey;
    margin-top: 8px;
    line-height: 26px;
    text-align: center;
  }
}