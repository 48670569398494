
          @import "src/assets/styles/variables.module.scss";
        
.layout {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: $white;
  position: relative;
  min-height: var(--app-height);

  &__colored {
    background-color: $cream;
    padding-bottom: 32px;
  }

  &__black {
    background-color: $black;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    height: 53px;
    margin-top: auto;
    width: 100%;
    z-index: 2;
  }

  &__content {
    margin-top: 53px;
    height: 100%;
    min-height: calc(var(--app-height) - 53px);
    display: flex;
    flex-direction: column;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 81px;
    margin-top: auto;
    width: 100%;

    &__promo {
      bottom: 10px;
    }
  }

  &--with-footer {
    padding-bottom: 81px;

    &__padding {
      padding-bottom: 100px;
    }

    .layout__content {
      min-height: calc(var(--app-height) - 134px);
    }
  }

  &--ios {
    padding-bottom: 113px;
    height: 100vh;

    .layout {
      &__content {
        min-height: calc(var(--app-height) - 166px);
      }

      &__footer {
        height: 113px;
      }
    }
  }
}
