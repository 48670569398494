
          @import "src/assets/styles/variables.module.scss";
        
.block {
  width: 100vw;
  height: var(--app-height);
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__back {
    background-color: transparent;
    position: absolute;
    left: 8px;
    top: 8px;
    height: 32px;
    width: 32px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    z-index: 10;
    padding: 0;
  }

  &__imageContainer {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__gradient_1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  &__gradient_2 {
    @extend .block__gradient_1;
    top: unset;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  &__button {
    position: absolute;
    width: calc(100% - 32px);
    bottom: 8px;
    left: 16px;
    min-width: auto;
  }
}
