
          @import "src/assets/styles/variables.module.scss";
        
.layout {
  display: flex;
  flex-direction: column;
  padding-bottom: $nav_height;
  height: var(--app-height);
  overflow: auto;

  &__padding {
    padding-bottom: 10px;
  }

  &__navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    height: $nav_height;
    margin-top: auto;
    width: 100%;
    z-index: 10;
  }
}
