
          @import "src/assets/styles/variables.module.scss";
        
.details {
  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  &__info {
    margin-top: 16px;
    border-top: 1px solid $grey_100;
  }

  &__block {
    margin-top: 16px;

    &__title {
      color: $main_grey;
      font-size: 14px;
      margin-bottom: 4px;
    }

    &__wallet {
      overflow-wrap: anywhere;
    }

    &__hash {
      overflow-wrap: break-word;
    }
  }
}