
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  overflow: auto;
  min-height: calc(var(--app-height) - 104px);
  max-height: calc(var(--app-height) - 104px);
  position: relative;

  &__title {
    color: $black;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__name {
    text-align: start;
    font-size: 16px;
    line-height: 27px;
    text-wrap: normal;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__description {
    text-align: start;
    font-size: 14px !important;
    color: $main_grey;
    line-height: 18px;
    width: 100%;
  }

  &__item {
    background-color: $grey_500;
    padding: 16px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border: 1px solid transparent;
    color: $black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    width: 100%;
    border-radius: 8px;

    &--active {
      border-color: $black;
    }
  }

  &__iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: $black;
  }

  &__description {
    color: $main_grey;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  &__buttonContainer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
  }
}
