
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  padding: 13px 16px;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  border-bottom: 1px solid $grey;
  background-color: $white;
  min-height: 53px;

  &__colored {
    background-color: $cream;
  }

  &__black {
    background-color: $black;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: $black;
    padding: 0;
    left: 8px;

    &__black {
      color: $white;
    }
  }

  &__title {
    font-weight: 500;
    line-height: 130%;
    margin-top: 0;
    font-size: 20px;
    width: 100%;
    text-align: center;
    padding: 0 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__black {
      color: $white;
    }
  }

  &__avatarContainer {
    position: absolute;
    right: 8px;
  }
}
