
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0 85px 0;

  &__imgContainer {
    height: 393px;
    width: auto;
    margin: 50px 0 60px 0;
  }

  &__img {
    object-fit: cover;
    height: 100%;
    width: auto;
  }

  &__logo {
    margin-bottom: 10px;
  }

  &__text {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;

    span {
      text-decoration: underline;
    }
  }

  &__button {
    margin-top: 24px;
    height: 60px;
    background-color: $black;
    color: $white;
    padding: 8px 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    border-radius: 76px;
  }
}
