
          @import "src/assets/styles/variables.module.scss";
        
.block {
  width: 100%;

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 170%;
    margin-bottom: 24px;
    margin-top: 11px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}
