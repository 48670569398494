
          @import "src/assets/styles/variables.module.scss";
        
.map {
  position: relative;
  height: calc(var(--app-height) - 47px - $nav_height);
  width: 100%;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999;
    background-color: $cream;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__card {
    width: 100%;
    height: 100px;
    background-color: $white;
  }

  &__container {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 8px;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  &__geoButton {
    z-index: 1;
    background-color: $black;
    color: $white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    pointer-events: all;
  }

  &__userMarker {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
