
          @import "src/assets/styles/variables.module.scss";
        
.nft {
  position: relative;
  background-color: $black;
  height: 234px;
  border-radius: 8px;
  padding: 6px;

  &__title {
    position: absolute;
    top: 15px;
    left: 15px;
    color: $white;
  }

  &__image {
    height: 182px;
    object-fit: cover;
    border-radius: 8px;
  }

  &__description {
    color: $white;
    margin-top: 12px;
  }
}