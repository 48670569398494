
          @import "src/assets/styles/variables.module.scss";
        
.page {
  align-items: center;
  min-height: var(--app-height);
  font-weight: 700;
  gap: 45px;
  height: 100%;
  display: grid;
  grid-template-rows: auto 390px;

  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }

  &__block {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    &--loading {
      margin-top: 210px;
    }
  }

  &__beta {
    color: #727272;
    text-align: center;
    margin-top: 8px;
    line-height: 160%;
    font-size: 16px;
    font-weight: 400;

    &__bold {
      font-weight: 700;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: -4px;
  }

  &__content {
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 24px 16px;
    // margin-top: auto;
    min-height: 390px;
    text-align: center;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;

    &--shown {
      transform: translateY(0);
    }
  }

  &__heading {
    font-size: 24px;
    line-height: 130%;
    font-weight: 500;
    margin-bottom: 24px;
  }

  &__divider {
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    margin: 16px 0;
    position: relative;
    background-color: $white;

    &::before {
      content: '';
      position: absolute;
      width: 45%;
      height: 1px;
      background-color: #c8c8c8;
      left: 0;
      top: 50%;
      transform: translateY(50%);
    }

    &::after {
      content: '';
      position: absolute;
      width: 45%;
      height: 1px;
      background-color: #c8c8c8;
      right: 0;
      top: 50%;
      transform: translateY(50%);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  &__socialButton {
    width: 56px;
    height: 56px;
    min-width: 56px;
  }

  &__googleContainer {
    position: relative;
    width: 56px;
    height: 56px;
    min-width: 56px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__googleButton {
    position: absolute;
    opacity: 0;
    z-index: 10;
  }

  &__overflowButton {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    background-color: $white;
    opacity: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__footer {
    margin-top: 48px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: #727272;

    a {
      color: $black;
      font-weight: 600;
    }
  }
}
