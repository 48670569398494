
          @import "src/assets/styles/variables.module.scss";
        
.toggle {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 48px;
    background-color: transparent;
    border: 1px solid $grey_100;

    &::before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: 3px;
      bottom: 3px;
      background-color: $grey;
      transition: 0.2s;
    }

    &--checked {
      &::before {
        background-color: $black;
      }
    }
  }
}

input:checked + .toggle__slider::before {
  transform: translateX(18px);
}
