
          @import "src/assets/styles/variables.module.scss";
        
.block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $grey;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &__box {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__name {
    color: $black;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  &__button {
    height: 30px;
    font-size: 14px;
    min-width: 92px;
    width: 92px;
    max-width: 92px;
    padding: 6px 12px;

    &--confirm {
      min-width: 76px;
      padding: 6px 10px;
      border: none;
    }

    &--delete {
      min-width: 65px;
      padding: 6px 10px;

    }
  }
}
