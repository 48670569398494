
          @import "src/assets/styles/variables.module.scss";
        
.more {
  &__button {
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;

    &:hover {
      background-color: #f5f5f5;
    }

    span {
      margin-left: 10px;
    }
  }
}