
          @import "src/assets/styles/variables.module.scss";
        
.form {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__row {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__forgot {
    display: block;
    margin-top: 16px;
    width: 100%;
    text-align: right;
    color: $black;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
  }

  &__button {
    margin-top: auto;
  }
  &__checkbox {
    margin-top: 16px;
    margin-bottom: 16px;

    &__email {
      margin-top: 0px;
      padding-bottom: 15px;
    }
  }
}
