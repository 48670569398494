
          @import "src/assets/styles/variables.module.scss";
        
.button {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.6);
  color: $white;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
