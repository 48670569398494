
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 16px;

  &__logo {
    margin-bottom: 16px;
  }

  &__text {
    color: $main_grey;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 160%;
  }

  &__imgContainer {
    position: relative;
    margin: 27px 0 32px 0;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 1/1;
    max-width: 328px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__description {
    @extend .block__text;
    font-size: 18px;
    max-width: 328px;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__dots {
    transform: rotate(90deg);
    width: 24px;
    height: 24px;
  }

  &__box {
    background-color: $white;
    border-radius: 8px;
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: $black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    margin: 12px 0 8px 0;

    &--safari {
      justify-content: space-between;
      padding: 0 24px 0 16px;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $cream;
  }
}
