
          @import "src/assets/styles/variables.module.scss";
        
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  height: var(--app-height);
  width: 100%;
  position: relative;
  overflow: hidden;

  &__header {
    height: 53px;
    width: 100%;
  }

  &__content {
    padding: 4px 0;
    height: calc(var(--app-height) - 114px);
    overflow: auto;
  }

  &__footer {
    height: 61px;
    width: 100%;
    background-color: $white;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--app-height) - 114px);
  }

  &--ios {
    .page {
      &__content {
        height: calc(var(--app-height) - 146px);
      }

      &__footer {
        height: 93px;
      }

      &__loader {
        height: calc(var(--app-height) - 146px);
      }
    }
  }
}
