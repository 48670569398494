
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__content {
    padding: 16px;
  }

  &__footer {
    padding: 16px 16px 25px;

    a {
      border: 1px solid $white;
    }
  }

  &__status {
    padding: 0 16px;
    margin-bottom: 16px;

    &__title {
      font-weight: 500;
      color: $white;
      font-size: 18px;
    }

    &__description {
      margin-top: 4px;
      color: $main_grey;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 500;
    color: $black;
    font-size: 18px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--app-height) - 165px);
  }
}

.details {
  overflow: scroll;
}

.block {
  padding: 10px 16px 30px 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: $black;

  &__footer {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    border: 1px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
  }
}
