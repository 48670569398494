
          @import "src/assets/styles/variables.module.scss";
        
.checkbox {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    outline: none;
    cursor: pointer;
  }

  &__input {
    -webkit-appearance: none;
    appearance: none;
    display: none;

    &--active {
      background-color: $black;
      position: relative;
    }
  }

  &__label {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $black;

    a {
      color: $black;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}
