
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  justify-content: space-between;

  &__avatar {
    display: flex;
    align-items: center;
  }

  &__info {
    font-size: 14px;
    margin-left: 12px;

    &__partner {
      font-weight: 600;
      color: $white;
    }

    &__date {
      font-weight: 300;
      color: $main_grey;
      margin-top: 2px;
    }
  }

  &__status {
    &__label {
      font-size: 14px;
      color: $white;
      border: 1px solid rgba(217, 217, 217, 0.3);
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__ongoing {
        background-color: rgba(0, 255, 87, 0.3);
        border: none;
      }

      &__finalization {
        background-color: rgba(255, 0, 0, 0.3);
        border: none;
      }

      &__ended {
        background-color: rgba(123, 123, 123, 0.3);
        border: none;
      }
    }
  }
}