
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: var(--app-height);
  padding: 64px 16px 32px 16px;

  &__title {
    color: $black;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 15px;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__description {
    text-align: start;
    font-size: 14px !important;
    color: $main_grey;
    line-height: 18px;
    width: 100%;
  }

  &__name {
    text-align: start;
    font-size: 16px;
    line-height: 27px;
    text-wrap: normal;
  }

  &__item {
    background-color: $white;
    padding: 16px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border: 1px solid transparent;
    color: $black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    width: 100%;
    border-radius: 8px;

    &--active {
      border-color: $main_grey;
    }
  }

  &__iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: $black;
    background-color: #F5F5F5;
    border-radius: 8px;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  &__description {
    color: $main_grey;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  &__buttonContainer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $cream;
    padding-bottom: 30px;
  }
}
