
          @import "src/assets/styles/variables.module.scss";
        
.tabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d8cfc6;
  position: relative;
  z-index: 5;
  background-color: $cream;

  &__tab {
    width: 100%;
    padding: 8px 0 12px 0;
    font-size: 20px;
    font-weight: 500;
    color: $main_grey;
    line-height: normal;
    text-align: center;

    &--active {
      color: $black;
      border-bottom: 3px solid $black;
      margin-bottom: -2px;
    }
  }
}
