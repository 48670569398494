
          @import "src/assets/styles/variables.module.scss";
        
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: var(--app-height);
  background-color: $white;
  width: 100%;
  position: relative;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }

  &__header {
    height: 53px;
    width: 100%;
    background-color: $cream;
  }

  &__content {
    overflow: auto;
    background-color: $cream;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    gap: 8px;
    padding: 8px;

    &__padding {
      padding-bottom: 61px;
    }
  }

  &__scroll {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    height: 100%;
  }

  &__footer {
    height: 61px;
    width: 100%;
    background-color: $white;
  }

  &__date {
    height: 30px;
    padding: 6px 12px;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey_transparent;
    border-radius: 59px;
    width: max-content;
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 50px 0;
    font-size: 16px;
    font-weight: 500;
    color: $main_grey;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--app-height) - 114px);
  }

  &--ios {
    .page {
      &__footer {
        height: 93px;
      }

      &__content {
        height: calc(var(--app-height) - 146px);
        padding-bottom: 8px;
      }

      &__loader {
        height: calc(var(--app-height) - 146px);
      }
    }
  }
}
