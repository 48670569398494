
          @import "src/assets/styles/variables.module.scss";
        
.page {
  padding: 16px;

  &__description {
    font-size: 16px;
    font-weight: 350;
  }

  &__label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__empty {
    color: #777;
    font-size: 16px;
    font-weight: 400;
  }
}
