.button {
  height: 56px;
  padding: 10px;
  background-color: $black;
  color: $white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  min-width: 328px;
  width: 100%;
  cursor: pointer;
  border: 1px solid $black;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--outline {
    background-color: transparent;
    border-color: $grey;
    color: $black;
  }

  &--outline-darker {
    background-color: transparent;
    border-color: $main_grey;
    color: $black;
  }

  &--outline-white {
    background-color: transparent;
    border-color: $white;
    color: $white;
  }

  &--white {
    background-color: $white;
    color: $black;
    border-color: $white;
  }

  &--grey {
    background-color: $grey_500;
    color: $black;
    border-color: $grey_500;
  }

  &--short {
    min-width: 49% !important;
    width: 49% !important;
  }

  @media screen and (max-width: 390px) {
    min-width: 100%;
  }
}
