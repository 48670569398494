
          @import "src/assets/styles/variables.module.scss";
        
.block {
  background-color: $black;
  min-height: 100vh;
  color: $white;
  display: flex;
  flex-direction: column;

  &__header {
    background-color: $black;
    border-bottom: 1px solid $grey_400;
    padding: 11px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 52px;
    width: 100%;
  }

  &__backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    outline: none;
    color: $white;
    background-color: transparent;
    position: absolute;
    left: 8px;
    width: 32px;
    height: 32px;
  }

  &__title {
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__content {
    padding: 16px;
    min-height: calc(100vh - 52px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 52px;
  }
}
