$white: #fff;
$black: #1c1b1b;
$black_900: #252525;
$cream: #f7ebdf;
$main_grey: #7b7b7b;
$grey_text: #5e5e5e;
$grey_text_200: #858585;
$grey: #c8c8c8;
$red: #f40c0c;
$text_grey: #727272;
$grey_100: #d9d9d9;
$grey_200: #fafafa;
$grey_300: #e7e7e7;
$grey_400: #777777;
$grey_500: #f7f8f9;
$background: #303030;
$cream_100: #f7ebdf;
$cream_200: #d8cfc6;
$grey_hover: #f5f5f5;
$cream_300: #ffdbb9;
$blue: #329dff;
$orange: #ffdbb9;
$yellow: #F7D41A;
$promo_grey: #9c9e9f;
$black_900: #363636;
$grey_transparent: rgba(123, 123, 123, 0.8);
$red_error: #EE3939;
$red_transparent: rgba(238, 57, 57, 0.05);

$nav_height: 84px;
