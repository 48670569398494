
          @import "src/assets/styles/variables.module.scss";
        
.block {
  &__label {
    font-size: 14px;
    font-weight: 400;
    color: $black;
    margin-bottom: 5px;

    span {
      color: $red;
    }
  }
}
