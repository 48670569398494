
          @import "src/assets/styles/variables.module.scss";
        
.clear {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;

  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    margin-bottom: 20px;
  }
}