
          @import "src/assets/styles/variables.module.scss";
        
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &__content {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
  }

  &__input {
    width: 100%;
    height: 100%;
    min-height: 50%;
    border: none;
    outline: none;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    resize: none;
    flex-grow: 1;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: $main_grey;
      line-height: 130%;
    }
  }

  &__imgContainer {
    width: 100%;
    height: 246px;
    min-height: 246px;
    position: relative;
    margin-top: auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__removeImgButton {
    width: 32px;
    height: 32px;
    background-color: #00000066;
    color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 8px;
  }
}
