
          @import "src/assets/styles/variables.module.scss";
        
.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: $black;
  padding: 12px 12px 20px 12px;
  border-top: 1px solid #404040;
  height: $nav_height;
  overflow: hidden;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    color: $main_grey;
    width: 67px;

    &--active {
      color: $white;
    }

    &--main {
      width: 48px;
      height: 48px;
      min-width: 48px;
      color: $black;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__title {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }

  &--ios {
    padding: 8px 12px 20px 12px;
    align-items: flex-start;

    .nav {
      &__link {
        justify-content: flex-start;

        &--main {
          justify-content: center;
        }
      }
    }
  }
}
