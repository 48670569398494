
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__label {
    color: $main_grey;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__scrollContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

    &--requests {
      margin-bottom: 48px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--app-height) - 140px);
  }
}
