
          @import "src/assets/styles/variables.module.scss";
        
.page {
  height: 100%;
  &__content {
    min-height: calc(var(--app-height) - 53px);
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__loader {
    height: calc(var(--app-height) - 53px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__empty {
    height: calc(var(--app-height) - 53px);
    text-align: center;
    font-size: 16px;
    color: $main_grey;
    font-weight: 400;
    line-height: 160%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
