
          @import "src/assets/styles/variables.module.scss";
        
.block {
  padding: 16px;

  &__title {
    color: $black_900;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 16px;
  }

  &__text {
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 160%;
  }
}
