
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  &__content {
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__imgContainer {
    max-width: 328px;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 12px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: auto;
    width: 100%;
  }
}
