.ReactModal {
  &__Content {
    bottom: 0 !important;
    left: 0;
    right: 0;
    width: 100vw !important;
    background-color: $white;
    height: max-content !important;
    inset: 0 !important;
    margin-top: auto;
    border-radius: 20px 20px 0 0 !important;
    border: none !important;
    transform: translate(0, 100%) !important;
    transition: transform 150ms ease-in-out !important;
    padding: 20px 16px !important;

    &--after-open {
      transform: translate(0) !important;
    }

    &--before-close {
      transform: translate(0, 100%) !important;
    }
  }

  &__Overlay {
    opacity: 0 !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
    transition: opacity 100ms ease-in-out !important;
  }

  &__Overlay--after-open {
    opacity: 1 !important;
  }

  &__Overlay--before-close {
    opacity: 0 !important;
  }
}
