
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  height: 57px;
  border-bottom: 1px solid rgba(123, 123, 123, 0.2);

  &__empty {
    font-size: 16px;
    font-weight: 400;
    color: $black;
    line-height: normal;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
  }

  &__place {
    color: $white;
    width: 10px;
    font-size: 17px;
  }

  &__start {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__end {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__rank {
    color: $black;
    font-size: 17px;
    font-weight: 400;
    line-height: normal;
  }

  &__avatarContainer {
    width: 40px;
    height: 40px;
    position: relative;
  }

  &__name {
    font-size: 16px;
    color: $white;
    font-weight: 400;

    &__me {
      color: #F7D41A;
    }
  }

  &__points {
    font-weight: 400;
    font-size: 14px;
    color: $main_grey;
    margin-top: 2px;
  }

  &__value {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;

    &__me {
      color: #F7D41A;
    }
  }

  &__currency {
    color: rgba(255, 255, 255, 0.5);

    &__me {
      color: #F7D41A;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
  }

  &__country {
    position: absolute;
    width: 12px;
    height: 12px;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.ranking {
  padding: 0 16px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $white;
  }

  &__participants {
    &__title {
      margin-top: 12px;
      text-align: center;
      color: $white;
    }

    &__count {
      margin-top: 2px;
      text-align: center;
      font-size: 14px;
      color: $main_grey;
    }
  }
}
