
          @import "src/assets/styles/variables.module.scss";
        
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &__header {
    height: 103px;
    width: 100%;
  }

  &__content {
    padding: 16px 8px 16px 8px;
    overflow: auto;
    height: calc(var(--app-height) - 98px);
  }

  &--ios {
    .page__content {
      height: calc(var(--app-height) - 130px);
    }
  }
}
