
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__container {
    background-color: $white;
    border-radius: 8px;
    height: 52px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0px 8px;
  }

  &__input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: $black;
    font-weight: 400;
    line-height: 130%;
    padding: 0;
    pointer-events: none;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: $main_grey;
      line-height: 130%;
    }
  }

  &__avatarContainer {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $main_grey;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}
