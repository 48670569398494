
          @import "src/assets/styles/variables.module.scss";
        
.block {
  margin-top: 8px;
  padding: 0 16px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $white;
  }

  &__rewards {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid rgba(123, 123, 123, 0.2);

      &:last-child {
        border-bottom: none;
      }
    }

    &__win {
      display: flex;
      align-items: center;
    }

    &__medal {
      width: 40px;
      height: 40px;
      background-color: #262525;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__place {
      margin-left: 12px;
      color: $white;
    }

    &__amount {
      display: flex;
      align-items: center;
    }

    &__number {
      display: inline-block;
      font-weight: 500;
      color: $white;
      margin: 0 4px;
    }

    &__currency {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}