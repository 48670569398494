
          @import "src/assets/styles/variables.module.scss";
        
.background {
  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }

  button {
    margin-bottom: 8px;
  }
}