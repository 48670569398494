
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__loader {
    height: var(--app-height);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  } 
}
