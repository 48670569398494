
          @import "src/assets/styles/variables.module.scss";
        
.balance {
  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__smallTitle {
    font-size: 18px;
    font-weight: 500;
  }

  &__block {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &__bottom {
      border-bottom: 1px solid $cream_200;
    }

    &--body {
      width: calc(100% - 60px);
      margin-left: 12px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__subtitle {
    color: $grey_text_200;
    font-size: 14px;
    margin-top: 4px;
  }
}