
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 17px 16px;
  background-color: $white;
  border-top: 1px solid $grey_100;

  &__imageButton {
    width: 56px;
    min-width: 56px;
    height: 56px;
  }

  &__button {
    min-width: auto;
  }
}
