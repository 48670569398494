
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 48px 16px;

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    margin-top: 24px;
  }

  &__description {
    color: $main_grey;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    margin-top: 8px;
  }

  &__iconContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
