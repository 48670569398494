
          @import "src/assets/styles/variables.module.scss";
        
.achievement {
  margin-top: 48px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 14px;
    color: $grey_text_200;
  }
}