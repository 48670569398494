
          @import "src/assets/styles/variables.module.scss";
        
.page {
  p {
    margin: 0;
  }
  &__content {
    padding: 16px;
    margin: 0 auto;

    &__loading {
      height: calc(var(--app-height) - 114px);
      display: flex;
      align-items: center;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 500;
    color: $white;
    font-size: 18px;
    text-align: center;
  }

  &__text {
    font-size: 16px;
    line-height: 161%;
    font-weight: 350;
    color: $white;
    font-style: normal;
    max-width: 324px;
    text-align: justify;

    p {
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      font-size: inherit;
    }
  }
}
