
          @import "src/assets/styles/variables.module.scss";
        
.cards {
  margin-top: 48px;
  margin-bottom: 16px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 154px);
    column-gap: 12px;
    row-gap: 8px;
    margin-bottom: 16px;
  }

  &__button {
    border: 1px solid $cream_200;
    background: transparent;
    cursor: not-allowed;
  }

  &__transfer {
    font-size: 16px;
    font-weight: 500;
    color: $black;
    margin-right: 10px;
  }

  &__coming {
    color: $main_grey;
    font-weight: 500;
  }
}