
          @import "src/assets/styles/variables.module.scss";
        
.item {
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 8px;
  gap: 12px;
  width: 100%;
  height: 72px;
  color: $black;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    border-bottom: 1px solid $grey;
    width: 100%;
    height: 100%;
    padding: 8px 0;
  }

  &__name {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }

  &__status {
    color: $main_grey;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}
