
          @import "src/assets/styles/variables.module.scss";
        
.block {
  background-color: $black;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__overlay {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 10%;
    left: 0;
    background-color: transparent;
  }

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__camera {
    width: 100%;
    height: 100%;

    &--hide {
      display: none;
    }
  }

  &__frameContainer {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 76%;
  }

  &__frame {
    width: 100%;
    position: relative;
  }

  &__frameIcon {
    width: 100%;
    height: auto;
  }

  &__bar {
    width: calc(100% - 8px);
    height: 4px;
    background-color: $red;
    position: absolute;
    left: 50%;
    top: 4px;
    animation: scanning 8s infinite ease;
    transform: translateX(-50%);
  }

  &__text {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    text-align: center;
    max-width: 275px;
    margin: 12px auto 0 auto;
  }

  &__back {
    position: absolute;
    left: 8px;
    top: 12px;
    z-index: 1;
  }

  &__errorBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
  }

  &__error {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 160%;
    margin-top: 16px;
  }
}

@keyframes scanning {
  0% {
    top: 4px;
  }
  50% {
    top: calc(100% - 8px);
  }
  100% {
    top: 4px;
  }
}
