
          @import "src/assets/styles/variables.module.scss";
        
.withdraw {
  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
  }

  &__subtitle {
    text-align: center;
    color: $main_grey;
  }

  &__row {
    margin-top: 16px;

    &__bottom {
      padding-bottom: 16px;
      border-bottom: 1px solid $grey;
    }
  }

  &__warning {
    display: block;
    font-size: 14px;
    margin-top: 5px;
    height: 17px;
  }

  &__button {
    margin-top: 16px;
  }

  &__fee {
    margin-top: 16px;

    &__title {
      font-weight: 500;
    }
  }
}

.select {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__titles {
    color: $main_grey;
    font-size: 14px;
  }

  &__balance {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      color: $main_grey;
    }

    &__top {
      margin-top: 16px;
    }
  }

  &__number {
    color: $black !important;
    font-size: 14px;
  }
}