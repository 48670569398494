
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__buttons {
    margin-top: auto;
    padding: 0 16px 32px 16px;
  }

  &__footer {
    margin-top: 16px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: $text_grey;

    a {
      color: $black;
      font-weight: 600;
    }
  }
}
