
          @import "src/assets/styles/variables.module.scss";
        
.transaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &__type {
    display: flex;
    align-items: center;
  }

  &__arrow {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;

    &__top {
      background-color: $cream_300;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
  }

  &__data {
    font-size: 14px;
    font-weight: 300;
    color: $main_grey;
    margin-top: 5px;
  }

  &__amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__currency {
    font-size: 16px;
    font-weight: 500;

    span {
      margin-left: 5px;
    }
  }
}