
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &__content {
    padding: 16px 16px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__feedContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__empty {
    color: #777;
    font-size: 16px;
    font-weight: 400;
  }
}
