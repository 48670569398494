
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(var(--app-height) - $nav_height);
  }
}
