
          @import "src/assets/styles/variables.module.scss";
        
.logout {
  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 4px;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
    color: $main_grey;
    text-align: center;
  }
}