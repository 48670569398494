
          @import "src/assets/styles/variables.module.scss";
        
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 170%;
    text-align: center;
  }

  &__description {
    color: $main_grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    text-align: center;
    margin-bottom: 24px;
  }
}
