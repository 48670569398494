
          @import "src/assets/styles/variables.module.scss";
        
.block {
  margin-top: 8px;
  padding: 0 16px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $white;
  }

  &__description {
    font-size: 14px;
    color: $main_grey;
    margin-top: 4px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid rgba(123, 123, 123, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__round {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: rgba(123, 123, 123, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  &__task {
    color: #858585;
    font-size: 14px;
  }

  &__outline {
    max-width: 75%;
  }

  &__titles {
    font-weight: 500;
    color: $white;
    margin-top: 2px;
  }

  &__joined {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__background {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-size: 24px;
      font-weight: 500;
      color: $white;
      line-height: 31px;
      margin-top: 12px;
      text-align: center;
    }

    &__announce {
      line-height: 26px;
      margin-top: 12px;
      color: $main_grey;
      text-align: center;
    }
  }

  svg {
    max-width: none;
  }
}