
          @import "src/assets/styles/variables.module.scss";
        
@import './layout.scss';
@import './buttons.scss';
@import './modal.scss';

:root {
  --app-height: 100vh;
}

#root {
  isolation: isolate;
}

html {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $cream;
  color: $black;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  height: var(--app-height);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

* {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-select {
  font-family: 'Lexend', sans-serif !important;

  &__value-container {
    display: flex !important;
    padding: 0 16px !important;
    flex-wrap: nowrap !important;
  }

  .select-input {
    &__value-container {
      svg {
        border: 1px solid #edeceb;
      }
    }

    &__control {
      min-height: 56px;
      height: 56px;
      cursor: pointer;
      border: 1px solid $grey;
      border-radius: 8px;
      padding-left: 10px;

      &--is-focused {
        box-shadow: none;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      svg {
        width: 24px;
        height: 24px;
        color: #777777;
      }
    }

    &__placeholder {
      color: $main_grey;
      font-size: 16px;
      font-weight: 400;
    }

    &__input-container {
      color: $black;
      font-size: 16px !important;
      font-weight: 500 !important;

      &::after {
        font-weight: 500 !important;
      }
    }

    &__single-value {
      color: $black;
      font-size: 16px;
      font-weight: 400;
      margin-left: -4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__input {
      caret-color: transparent !important;
      input {
        color: $black;
        font-size: 16px;
        font-weight: 500;
        caret-color: transparent !important;

        &::placeholder {
          color: $black;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    &__indicator {
      color: $black;
      padding: 13px 13px 13px 0;
    }

    &__menu {
      min-width: 150px;
      max-height: 225px;
      overflow: hidden;
      border-radius: 8px;
    }

    &__option {
      &--is-selected {
        background-color: $cream;
        color: $black;
      }
    }

    &__value-container {
      padding: 2px 2px 2px 8px;
    }
  }
}

.form {
  &__error {
    font-size: 14px;
    font-weight: 400;
    color: $red;
    line-height: normal;
    margin-top: 8px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.react-html5-camera-photo {
  height: 100%;
  display: flex;

  video {
    object-fit: cover;
    height: 100%;
  }

  #container-circles {
    bottom: 115px;
  }

  #outer-circle {
    background-color: transparent;
    border: 2px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  #inner-circle {
    background-color: $white;
    margin: 0;
    position: static;
    width: 45px;
    height: 45px;
  }

  & #display-error {
    display: none;

    #container-circles {
      display: none !important;
    }
  }
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.gm-style iframe + div {
  border: none !important;
}

.rdp-head_row {
  color: $main_grey;
}

.rdp-caption {
  position: relative;
  border-bottom: 1px solid $grey_100;
  padding-bottom: 8px !important;
  justify-content: center !important;
}

.rdp-caption_label {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.rdp-months {
  justify-content: center;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.rdp-nav_button_previous {
  position: absolute !important;
  left: 0;
  top: -6px;

  svg {
    color: #777777 !important;
  }
}

.rdp-nav_button_next {
  position: absolute !important;
  right: 0;
  top: -6px;

  svg {
    color: #777777 !important;
  }
}

.rdp-head_cell {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 9px !important;
  color: #9291a5 !important;
}

.rdp-day {
  padding: 21px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.rdp-day_selected {
  background-color: $black !important;
}

.rdp-month {
  height: 350px;
}

.gm-style iframe + div {
  border: none !important;
}

.react-tel-input {
  .form-control {
    padding-left: 71px !important;
    font-size: 16px !important;
  }

  .flag-dropdown {
    border-radius: 8px 0 0 8px !important;
    background: transparent !important;
  }

  .selected-flag {
    width: 55px !important;
    border-radius: 8px 0 0 8px !important;
    padding: 0 0 0 16px !important;
  }
}


.Toastify__toast-container {
  position: absolute !important;
}