
          @import "src/assets/styles/variables.module.scss";
        
.card {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__imgContainer {
    height: auto;
    background-color: $white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    aspect-ratio: 152 / 166;
  }

  &__img {
    height: 100%;
    object-fit: contain;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__titleContainer {
    flex: 1 1 100%;
    min-width: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__year {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: $main_grey;
    margin-top: 4px;
  }

  &__price {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    color: $white;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    span {
      font-size: 16px;
      font-weight: 400;
      color: $main_grey;
    }
  }
}
