
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  flex-direction: column;
  padding: 11px 16px;
  transition: all 0.3s ease;

  &__step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    font-weight: 500;
  }

  &__button {
    background-color: transparent;
    border: none;
    color: $black;
    padding: 0;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 130%;
    margin-top: 22px;
  }

  &__description {
    color: $text_grey;
    font-size: 16px;
    line-height: 160%;
    font-weight: 400;
    margin-top: 10px;
    padding-right: 1px;

    b {
      font-weight: 600;
      color: $black;
    }
  }

  &--min {
    flex-direction: row;
    align-items: center;
    gap: 40px;
    border-bottom: 1px solid $grey;

    .block {
      &__title {
        margin-top: 0;
        font-size: 20px;
        line-height: 130%;
      }
    }
  }
}
