
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  background-color: $white;
  width: max-content;
  padding: 8px 16px;
  border-radius: 16px 16px 16px 4px;
  gap: 12px;
  max-width: 280px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  &--owner {
    background-color: $black_900;
    color: $white;
    align-self: flex-end;
    border-radius: 16px 16px 4px 16px;
  }

  &--image {
    padding: 0;
    width: 250px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 16px;

    .block__date {
      position: absolute;
      bottom: 8px;
      right: 8px;
      background: $grey_transparent;
      color: $white;
      border-radius: 78px;
      height: 18px;
      padding: 2px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__image {
    background-color: $white;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    font-size: 17px;
    font-weight: 400;
    word-break: break-word;
  }

  &__date {
    color: $grey;
    font-size: 11px;
    font-weight: 300;
    line-height: normal;
    align-self: flex-end;
  }

  &__loader {
    height: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
