
          @import "src/assets/styles/variables.module.scss";
        
.sales {
  margin-top: 48px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    &--title {
      font-weight: 500;
    }

    &--subtitle {
      color: $grey_text_200;
    }
  }

  &__range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    &--title {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $grey_text_200;
      margin-bottom: 8px;
    }

    &--width {
      width: 105px;
      justify-content: flex-end;
      cursor: pointer;
    }

    &--subtitle {
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__arrow {
    margin-left: 4px;

    &__top {
      transform: rotate(180deg);
    }
  }

  &__modal {
    font-size: 24px;
    font-weight: 500;
    margin: 16px 0;
    text-align: center;
  }

  &__total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__options {
    padding: 16px 10px;
    font-weight: 500;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background-color: $grey_hover;
    }
  }
}