
          @import "src/assets/styles/variables.module.scss";
        
.preview {
  position: relative;
  text-align: center;

  &__img {
    width: 100%;
  }

  &--fullscreen {
    height: 100vh;
    width: 100vw;

    .preview__img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
