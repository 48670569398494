
          @import "src/assets/styles/variables.module.scss";
        
.marker {
  width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &__icon {
    position: absolute;
    width: 100%;
    height: 61px;
    left: 0;
    top: 0;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
  }

  &__avatarContainer {
    position: relative;
    z-index: 1;
  }

  &__count {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    width: 16px;
    height: 16px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 122px;
    background-color: $blue;
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
  }

  &__shadow {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}
