
          @import "src/assets/styles/variables.module.scss";
        
.block {
  padding: 16px 12px;
  border-radius: 8px;
  background-color: $black;
  margin-bottom: 8px;

  &__link {
    padding: 16px;
  }

  &__info {
    padding: 12px 0;
    display: flex;
    align-items: center;
    gap: 14px;
    justify-content: space-between;

    &__link {
      cursor: auto;
    }

    &__title {
      font-size: 15px;
      font-weight: 700;
      color: $white;
    }

    &__description {
      font-size: 13px;
      color: $main_grey;
      margin-top: 4px;
      line-height: 18px;
    }
  }

  &__prize {
    background-color: rgba(217, 217, 217, 0.05);
    border-radius: 4px;
    padding: 12px;

    &__top {
      padding-bottom: 12px;
    }

    &__bottom {
      border-top: 1px solid rgba(217, 217, 217, 0.1);
      padding-top: 12px;
    }

    &__pool {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      &__title {
        font-weight: 500;
        color: $white;
      }

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &__reward {
        font-weight: 500;
        color: $white;
        display: inline-block;
        margin: 0 4px;
      }

      &__currency {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &__timer {
    display: flex;
    align-items: center;

    &__cell {
      background-color: $white;
      border-radius: 3px;
      font-weight: 500;
      color: $black;
      font-size: 14px;
      width: 25px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__marker {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      margin: 0 4px;
    }
  }

  svg {
    max-width: none;
  }
}
