
          @import "src/assets/styles/variables.module.scss";
        
.header {
  padding: 181px 16px 10px 16px;
  position: relative;

  &__background {
    pointer-events: none;
    background-color: $black;
    height: 116px;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    transition: 0.2s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &__name {
      z-index: 2;
    }

    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: flex-end;
    position: fixed;
    top: 56px;
    right: 16px;
    gap: 4px;
    z-index: 2;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    color: $white;
    background-color: rgba(0, 0, 0, 0.6);
    color: $white;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    position: relative;
  }

  &__buttonIcon {
    display: flex;
    width: 24px;
    height: 24px;
  }

  &__avatar {
    background-color: $white;
    width: 100% !important;
    height: 100% !important;
    svg {
      color: $main_grey;
      width: 35%;
    }
  }

  &__avatarWrapper {
    position: fixed;
    top: 0;
    left: 16px;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    z-index: 2;
  }

  &__avatarContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    background-color: $white;
    border-radius: 50%;
    border: 4px solid $cream_100;
    margin-top: 36px;
    margin-left: -5px;
    color: $main_grey;
    transition: 0.1s;

    &__confirmed {
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }

  &__name {
    color: $black;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 6px;
    word-wrap: break-word;
  }

  &__backgroundGradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  }

  &__backgroundName {
    color: $black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $white;
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60%;
    z-index: 10;
    transition: 0.2s ease-in-out;
  }

  &__position {
    color: $grey_text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }

  &__info {
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 12px;
  }

  &__grid {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 48px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__follow {
      cursor: pointer;
    }
  }

  &__itemLabel {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $grey_text_200;
  }

  &__itemValue {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $black;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $grey_text_200;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__text {
    color: $grey_text_200;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--black {
      color: $black;
    }
  }

  &__level {
    cursor: pointer;
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 8px;
    top: 8px;
    padding: 0;
    color: $white;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: none;
    z-index: 2;
  }

  &__buttonDot {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #f71a1a;
  }

  &--alternate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 142px;

    .header {
      &__background {
        height: 100px;
      }

      &__avatarWrapper {
        height: 108px;
        width: 108px;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
      }

      &__avatarContainer {
        margin-top: -12px;
        height: 108px;
        width: 108px;
      }

      &__name {
        margin-top: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }

      &__footer {
        align-items: center;
        width: 100%;
        margin-top: 24px;
      }

      &__followContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
      }

      &__followButton {
        width: 100%;
        min-width: auto;
        margin-top: 17px;
        height: 44px;
      }

      &__itemLabel {
        font-size: 16px;
      }
    }
  }

  &--fixed {
    padding: 212px 16px 10px 16px;

    &.header--alternate {
      padding-top: 200px;
    }

    .header {
      &__background {
        height: 52px;
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: $white;
      }

      &__buttons {
        display: none;
      }

      &__avatarWrapper {
        top: 9px;
        left: calc(100vw - 50px);
        width: 34px;
        height: 34px;
      }

      &__avatarContainer {
        width: 34px;
        height: 34px;
        border: none;
        margin-top: 0;
      }

      &__name {
        display: none;
      }

      &__back {
        position: fixed;
      }
    }
  }
}
