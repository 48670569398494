
          @import "src/assets/styles/variables.module.scss";
        
.page {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--app-height);
  }
}
