
          @import "src/assets/styles/variables.module.scss";
        
.page {
  align-items: center;
  font-weight: 700;
  gap: 62px;
  height: var(--app-height);
  display: grid;
  grid-template-rows: auto 274px;

  &__block {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;
    transition: all 0.3s ease-in-out;
  }

  &__iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  &__title {
    color: $black;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 24px;
  }

  &__description {
    color: $main_grey;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    max-width: 328px;
    margin-top: 8px;

    span {
      font-weight: 700
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: -4px;
  }

  &__content {
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 31px 16px 16px;
    min-height: 274px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__option {
      padding: 11px 0 0;
      min-height: auto;
    }
  }

  &__heading {
    font-size: 24px;
    line-height: 170%;
    font-weight: 500;
  }

  &__count {
    color: $main_grey;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border: 1px solid $grey_100;
    border-radius: 44px;
    padding: 4px 12px;
    height: 30px;
    width: max-content;
    margin-bottom: 16px;

    &__option {
      line-height: 20px;
    }
  }

  &__message {
    @extend .page__description;
    margin-top: 0;
  }

  &__button {
    margin-top: 16px;
    gap: 14px;
  }
}
