
          @import "src/assets/styles/variables.module.scss";
        
.select {
  width: 100%;
  font-family: 'Lexend', sans-serif !important;

  &__dropdown {
    border-radius: 8px !important;
  }

  &__input {
    width: 100% !important;
    height: 56px !important;
    border-radius: 8px !important;
  }

  &__phone {
    position: relative;

    &__confirmed {
      position: absolute;
      right: 12px;
      top: 17px;
      color: $main_grey;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: $black;
  margin-bottom: 5px;
}