
          @import "src/assets/styles/variables.module.scss";
        
.page {
  padding: 16px;
  min-height: 50vh;
  height: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    margin: 16px 0;
  }

  &__empty {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: $main_grey;
    height: 100%;
    margin-top: 90px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--app-height) - 281px);
  }
}
