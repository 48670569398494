
          @import "src/assets/styles/variables.module.scss";
        
.form {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__row {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__button {
    margin-top: auto;
  }

  &__checkbox {
    margin-top: 10px;
  }

  &__message {
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    margin-top: 12px;
  }
}
