
          @import "src/assets/styles/variables.module.scss";
        
.page {

  &__content {
    padding: 20px;
  }

  &__feedContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__empty {
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    color: $grey_400;
  }
}