
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0 16px 0;
  border-bottom: 1px solid $grey_100;
  margin-bottom: 16px;
  position: relative;

  &__info {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $black;
    font-weight: 500;
    line-height: 130%;
    gap: 12px;
  }

  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    color: $main_grey;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding: 0;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    color: $black;
  }

  &__item {
    padding: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;
    color: $black;

    &:not(:last-child) {
      border-bottom: 1px solid $grey_100;
    }

    &--active {
      background-color: $cream;
    }
  }
}
