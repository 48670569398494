
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background-color: $white;
  border-top: 1px solid $grey_100;
  margin-top: auto;

  &__sendButton {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    min-width: 44px;
    color: $white;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    outline: none;

    &:disabled {
      background-color: $main_grey;
    }
  }

  & [contenteditable='true']:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    color: $main_grey;
    display: block; /* For Firefox */
  }

  &__input {
    height: 44px;
    min-height: 44px;
    border-radius: 8px;
    border: 1px solid $grey_300;
    padding: 12px;
    outline: none;
    width: 100%;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    max-height: 44px;
    height: 100%;
    color: $black;

    * {
      color: $black !important;
      background-color: $white !important;
      font-size: inherit !important;
      font-family: inherit !important;
      text-decoration: inherit !important;
      text-transform: inherit !important;
      white-space: pre-wrap !important;
      line-height: inherit !important;
    }
  }

  &--focus {
    max-height: 192px;
    position: absolute;
    bottom: 0;
    align-items: flex-start;

    .block {
      &__input {
        max-height: 192px;
        overflow: auto;
      }
    }
  }
}
