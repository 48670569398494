
          @import "src/assets/styles/variables.module.scss";
        
.form {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__email {
    margin-bottom: 15px;
  }

  &__row {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
}
