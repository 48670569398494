
          @import "src/assets/styles/variables.module.scss";
        
.block {
  height: 312px;
  background-color: $black;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $white;
  padding: 32px 16px;

  &__back {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    padding: 0;
    z-index: 2;
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(28, 27, 27, 0.5) 0%,
      rgba(28, 27, 27, 0.5) 100%
    );
  }

  &__imgContainer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }

  &__img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 4;
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    line-height: normal;
    color: $white;
  }

  &__type {
    color: $grey_100;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    margin-bottom: 8px;
    text-transform: capitalize;
  }

  &__address {
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 4px;
  }

  &__sommeliersContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-top: 16px;
  }
}
