
          @import "src/assets/styles/variables.module.scss";
        
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--app-height);
  max-width: 100vw;
  flex-direction: column;
}


@media screen and (max-width: 1024px) {
  .page {
    justify-content: flex-start;

    &__container {
      padding: 80px 20px 85px 20px;
    }
  }
}