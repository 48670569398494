
          @import "src/assets/styles/variables.module.scss";
        
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-weight: 700;
  gap: 120px;

  &__flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
