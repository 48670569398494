
          @import "src/assets/styles/variables.module.scss";
        
.form {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__row {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__forgot {
    display: block;
    margin-top: 16px;
    text-align: right;
    color: $black;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 16px;
    margin-left: auto;
  }

  &__button {
    margin-top: auto;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
