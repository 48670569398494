
          @import "src/assets/styles/variables.module.scss";
        
.chart {
  margin-top: 48px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__arrow {
    margin-left: 4px;
  }

  &__round {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__modal {
    font-size: 24px;
    font-weight: 500;
    margin: 16px 0;
    text-align: center;
  }

  &__options {
    padding: 16px 10px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: $grey_hover;
    }
  }

  &--all {
    background: #2891F9;
  }

  &--red {
    background: #F71A1A;
  }

  &--rose {
    background: #F68FFF;
  }

  &--white {
    background: #9C9E9F;
  }

  &--sweet {
    background: #F7D41A;
  }

  &--sparkling {
    background: $black;
  }

  &--fortified {
    background: #23E64A;
  }

  &__filter {
    display: flex;
    align-items: center;

    &--title {
      font-size: 14px;
      color: $grey_text_200;
      display: flex;
      align-items: center;
      margin-left: 16px;
      cursor: pointer;
    }

    &--width {
      width: 75px;
      justify-content: end;
    }
  }
}