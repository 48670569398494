
          @import "src/assets/styles/variables.module.scss";
        
.block {
  width: 100%;

  &__list {
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    gap: 24px;
    row-gap: 22px;
    width: 100%;
  }
}
