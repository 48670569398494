
          @import "src/assets/styles/variables.module.scss";
        
.editChange {
  &__subtitle {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__email {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}