
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0 16px 0;
  position: relative;
  width: 100%;

  &__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    color: $black;
    z-index: 100;
    max-height: 150px;
    overflow: auto;
    width: 100%;
    padding: 4px 0;
  }

  &__item {
    padding: 8px 12px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;
    color: $black;

    &--active {
      background-color: #DEEBFF;
    }
  }
}
