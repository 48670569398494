
          @import "src/assets/styles/variables.module.scss";
        
.block {
  padding: 16px 15px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  gap: 12px;
  background-color: transparent;
  border: none;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid $grey_100;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__iconContainer {
    width: 40px;
    height: 40px;
    background-color: $grey_hover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: $black;
  }

  &__title {
    font-size: 14px;
    color: $black;
    font-weight: 500;
    line-height: 160%;
  }

  &__date {
    font-size: 14px;
    font-weight: 400;
    color: $main_grey;
    line-height: 130%;
  }

  &__description {
    font-size: 14px;
    font-weight: 350;
    line-height: 150%;
    color: $black;
  }
}
