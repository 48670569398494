
          @import "src/assets/styles/variables.module.scss";
        
.form {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__row {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__email {
    margin-bottom: 15px;
  }

  &__timerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin: 16px 0;
  }

  &__message {
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  &__value {
    color: $black;
    font-weight: 500;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    min-width: 45px;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.button {
  padding: 0 16px;
  margin-top: 21px;
}
