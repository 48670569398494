
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: $cream;

  &__border {
    border: 1px solid $white;
  }

  &__disabled {
    pointer-events: none;
  }

  &--white {
    background-color: $white;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $white;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &__blur {
      filter: blur(2px);
    }
  }

  &__icon {
    width: 60%;
    height: 60%;
    color: $black;

    &__blur {
      filter: blur(2px);
    }
  }
}
