
          @import "src/assets/styles/variables.module.scss";
        
.page {
  overflow: hidden;
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &__content {
    padding: 61px 16px 16px;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__feedContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height:100%;
    overflow: hidden;
    margin-top: 16px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--app-height) - 202px);
  }
}
