
          @import "src/assets/styles/variables.module.scss";
        
.block {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 450px;
    overflow: auto;

    &__spinner {
      height: calc(var(--app-height) - 202px);
      display: flex;
      align-items: center;
    }
  }
}
