
          @import "src/assets/styles/variables.module.scss";
        
.block {
  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 170%;
    margin-bottom: 24px;
  }

  &__disabled {
    pointer-events: none;
  }
}
