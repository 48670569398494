
          @import "src/assets/styles/variables.module.scss";
        
.block {
  background-color: $black;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__camera {
    width: 100%;
    height: 100%;

    &--hide {
      display: none;
    }
  }

  &__frameContainer {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 76%;
    max-height: 40vh;
    margin-top: -65px;
    flex-shrink: 1;
    pointer-events: none;
  }

  &__frame {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 60vh;
  }

  &__frameIcon {
    width: auto;
    height: 100%;
    max-height: 60vh;
  }

  &__bar {
    width: calc(100% - 8px);
    height: 4px;
    background-color: #f40c0c;
    position: absolute;
    left: 50%;
    top: 4px;
    animation: scanning 8s infinite ease;
    transform: translateX(-50%);
  }

  &__back {
    position: absolute;
    left: 8px;
    top: 12px;
    z-index: 1;
  }

  &__errorBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
  }

  &__error {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 160%;
    margin-top: 16px;
  }
}

@keyframes scanning {
  0% {
    top: 4px;
  }
  50% {
    top: calc(100% - 8px);
  }
  100% {
    top: 4px;
  }
}
