
          @import "src/assets/styles/variables.module.scss";
        
.marker {
  width: 36px;
  height: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: 50%;

  &__avatarContainer {
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
