
          @import "src/assets/styles/variables.module.scss";
        
.card {
  width: 100%;
  height: auto;
  background-color: $white;
  border-radius: 8px;
  padding: 16px 8px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);

  &--list {
    box-shadow: none;
  }

  &__avatarContainer {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid $grey_100;
    width: 100%;
  }

  &__info {
    width: 100%;
  }

  &__nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 16px;
  }

  &__name {
    color: $black;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__description {
    color: $main_grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3px;
  }

  &__distance {
    color: $main_grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    margin-bottom: 5px;
    white-space: nowrap;
    width: max-content;
  }

  &__body {
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  &__label {
    overflow: hidden;
    color: $main_grey;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__value {
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
