
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: $white;
  border-radius: 8px;
  width: 100%;
  transition: all 0.3s ease;

  &__iconContainer {
    background: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    border: 1px solid $grey_100;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__message {
    color: $black;
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
  }

  &__success {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  &__icon {
    color: $red_error;
    width: 16px;
    height: 16px;
  }
}
