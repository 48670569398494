
          @import "src/assets/styles/variables.module.scss";
        
.container {
  position: fixed;
  left: 0;
  top: 0;
  height: var(--app-height);
  width: 100%;
  z-index: 100;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  &__content {
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__imgContainer {
    max-width: 328px;
    height: 328px;
    border-radius: 8px;
    overflow: hidden;
    background-color: $white;
    padding: 8px;
    width: 100%;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__title {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 15px;
  }

  &__year {
    margin-top: 8px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: $main_grey;
  }

  &__divider {
    margin: 8px 0;
    background-color: $grey_400;
    height: 1px;
    width: 100%;
  }

  &__price {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-bottom: 16px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: auto;
    width: 100%;
  }
}
