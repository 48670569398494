
          @import "src/assets/styles/variables.module.scss";
        
.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $cream_200;

  &__link {
    padding: 14px 33px;
    color: $black;
    outline: none;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      border-bottom: 3px solid $black;
    }
  }
}
