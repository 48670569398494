
          @import "src/assets/styles/variables.module.scss";
        
.sommelier {
  padding: 20px 16px;
  background-color: $white;
  border-radius: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__level {
    text-transform: uppercase;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    padding: 2px 8px;
    background-color: $black;
    border-radius: 42px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__name {
    margin-left: 12px;
    font-weight: 500;
    color: $black;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    color: $main_grey;
    line-height: 22px;
  }

  &__range {
    font-weight: 500;
    color: $black;
    line-height: 20px;
  }
}