
          @import "src/assets/styles/variables.module.scss";
        
.level {
  padding: 11px 16px 0 16px;

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    color: $black;
  }

  &__subtitle {
    margin-top: 16px;
    color: $main_grey;
    line-height: 26px;
    width: 234px;
  }

  &__new {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: $black;
    margin-top: 24px;
  }

  &__complete {
    font-size: 14px;
    line-height: 22px;
    color: $main_grey;
    margin-top: 8px;
    margin-bottom: 32px;
  }
}

@media (min-width: 800px) {
  .level {
    &__subtitle {
      width: 100%;
    }
  }
}