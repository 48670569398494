
          @import "src/assets/styles/variables.module.scss";
        
.block {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &__bottom {
    padding-bottom: 16px;
    border-bottom: 1px solid $cream_200;
  }

  &__icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid $cream_200;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  &__title {
    color: $grey_text_200;
    font-size: 14px;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 500;
    margin-top: 4px;
  }
}